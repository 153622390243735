import React, { useEffect, useState } from "react";
import MainPagetitle from "../../../layouts/MainPagetitle";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import dayjs from "dayjs";

const GenerateAgreement = (props) => {
  const activeStatus = props?.urlparams?.params?.status;
  const path = props?.urlparams?.params?.path;
  const [fields, setFields] = useState([
    {
      name: "borrowerName",
      label: "Customer Name",
      type: "text",
      readOnly: true,
    },
    { name: "mobileNumber", label: "Mobile No.", type: "text", readOnly: true },
    { name: "emailAddress", label: "Email ID", type: "text", readOnly: true },
    {
      name: "loanAccountNumber",
      label: "Loan Account No",
      type: "text",
      readOnly: true,
    },
    {
      name: "sanctionedLoanAmount",
      label: "Sanctioned Loan amount",
      type: "text",
      onChange: (date) => handleLoanAmountChange(date),
      // readOnly: true,
    },
    {
      name: "sanctionedLoanTenure",
      label: "Tenure",
      type: "number",
      onChange: (date) => handleTenureChange(date),
    },
    { name: "sanctionedLoanIRR", label: "ROI", type: "text", readOnly: true },
    {
      name: "sanctionedLoanPF",
      label: "Processing Fees",
      type: "text",
      readOnly: true,
    },
    {
      name: "loanPaymentMode",
      label: "Repayment Type",
      type: "text",
      readOnly: true,
    },
    {
      name: "sanctionedLoanStartDate",
      label: "Loan Start date",
      type: "date",
      onChange: (date) => handleStartDateChange(date),
      disabledDate: (current) => {
        return current && current < moment().startOf("day");
      },
      required: true,
    },
    {
      name: "sanctionedLoanEndDate",
      label: "Loan End date",
      type: "date",
      dateClassName: "fakedisabled",
    },
    {
      name: "sanctionedLoanTotalAmtPayable",
      label: "Total Amt payable",
      type: "text",
      readOnly: true,
    },
    // {
    //   name: "stampDutyNum",
    //   label: "Stamp Duty No.",
    //   type: "number",
    //   required: true,
    // },
    {
      name: "netDisbursementAmt",
      label: "Net Disbursement Amt.",
      type: "number",
      readOnly: true,
      required: true,
    },
    {
      name: "remarks",
      label: "Remarks",
      type: "textarea",
      required: true,
      colspan: 12,
    },
  ]);
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getData();
  }, []);

  const handleStartDateChange = (date) => {
    let duration = document.querySelector(
      "[name='sanctionedLoanTenure']"
    ).value;

    let loanEndDateValue = dayjs(date).add(duration - 1, "day");

    document.querySelector("[name='sanctionedLoanEndDate']").value =
      dayjs(loanEndDateValue).format("YYYY-MM-DD");

    // calculate total payable amount
    let loanAmount = document.querySelector(
      "[name='sanctionedLoanAmount']"
    ).value;
    let interestAmount = Number(loanAmount) * 0.01 * duration;
    let totalAmount = (Number(loanAmount) + interestAmount).toFixed(2);
    document.querySelector("[name='sanctionedLoanTotalAmtPayable']").value =
      totalAmount;
  };

  const handleTenureChange = (e) => {
    let duration = e.target.value;

    let loanStartDateValue = document.querySelector(
      "[name='sanctionedLoanStartDate']"
    ).value;

    if (loanStartDateValue) {
      let loanEndDateValue = dayjs(loanStartDateValue).add(duration - 1, "day");

      document.querySelector("[name='sanctionedLoanEndDate']").value =
        dayjs(loanEndDateValue).format("YYYY-MM-DD");

      // calculate total payable amount
      let loanAmount = document.querySelector(
        "[name='sanctionedLoanAmount']"
      ).value;
      let interestAmount = Number(loanAmount) * 0.01 * duration;
      let totalAmount = (Number(loanAmount) + interestAmount).toFixed(2);
      document.querySelector("[name='sanctionedLoanTotalAmtPayable']").value =
        totalAmount;
    }
  };

  const handleLoanAmountChange = (e) => {
    e.preventDefault();
    let loanAmount = e.target.value;
    let sanctionedLoanPctPF = fields.find(
      (field) => field.name === "sanctionedLoanPF"
    )?.sanctionedLoanPctPF;

    let processingFee = (Number(loanAmount) * sanctionedLoanPctPF) / 100;
    document.querySelector("[name='sanctionedLoanPF']").value = processingFee;
    document.querySelector("[name='netDisbursementAmt']").value = Number(loanAmount)-processingFee;
    document.querySelector("[name='sanctionedLoanEndDate']").value =null;
    document.querySelector("[name='sanctionedLoanStartDate']").value =null;

  };

  const updateDefaults = (arr, applicationData) => {
    return arr.map((o) => {
      let defaultValue = applicationData[o.name] || o.defaultValue || "";
      if (o.type == "select") {
        if (o.getOptions instanceof Function && o.options.length == 0) {
          o.options = o.getOptions(applicationData);
        }
        if (
          typeof defaultValue == "string" &&
          defaultValue.split(",").length > 1
        ) {
          defaultValue = defaultValue
            .split(",")
            .map((key) => o.options.find((o) => o.value == key));
        } else if (o.multiple) {
          defaultValue = o.options?.filter((o) =>
            defaultValue?.includes(o.value)
          );
        } else {
          defaultValue = o.options?.find((o) => o.value == defaultValue);
        }
      }
      if (o.type === "date") {
        console.log({ name: o.name, defaultValue });
        return {
          ...o,
          defaultValue: defaultValue,
        };
      }
      if (o.name === "sanctionedLoanTotalAmtPayable") {
        let interestRate = applicationData.sanctionedLoanIRR;
        let loanAmount = applicationData.sanctionedLoanAmount;
        let loanduration = applicationData.sanctionedLoanTenure;
        // console.log({
        //   interestRate,
        //   loanAmount,
        //   loanduration,
        // });
        let interestAmount = loanAmount * 0.01 * loanduration;
        let totalAmount = (loanAmount + interestAmount).toFixed(2);
        return {
          ...o,
          defaultValue: totalAmount,
        };
      }
      if (o.name === "sanctionedLoanPF") {
        o.sanctionedLoanPctPF = applicationData?.sanctionedLoanPctPF || 10;
      }
      return {
        ...o,
        defaultValue,
      };
    });
  };

  const handleAgreement = (e, data) => {
    let payload = { ...data };
   if(payload?.sanctionedLoanStartDate){ for (let key in data) {
      if (
        key.includes("Amt") ||
        key.includes("Amount") ||
        key.includes("Tenure") ||
        key.includes("IRR") ||
        key.includes("LoanPF")
      ) {
        payload[key] = parseFloat(payload[key] || 0);
      }
    }
    const resolve = (res) => {
      // Create a PDF download link
      let atag = document.createElement("a");
      atag.href = res?.pdf || "";
      atag.download = "loan-agreement.pdf";
      atag.setAttribute("target", "_blank");
      document.body.appendChild(atag);
      atag.click();
      document.body.removeChild(atag);

      navigate(`/app/loanapplications?activetab=${activeStatus}`);
      swal2.fire({
        title: `Loan ${
          path === "generateagreement" ? "Generated" : "Re-Generated"
        } Successfully`,
        icon: "success",
        iconColor: "#DE3232",
        timer: 3000,
        confirmButtonText: "Close",
      });
    };

    swal2
      .fire({
        title: `Are you sure want to ${
          path === "generateagreement" ? "generate" : "re-generate"
        } the agreement`,
        icon: "info",
        iconColor: "#DE3232",
        showCancelButton: true,
        confirmButtonColor: "#DE3232",
        cancelButtonColor: "##D6DBE7",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {
          payload = {
            ...Object.except(payload, ["loanAccountNumber"]),
            sanctionedLoanStartDate: new Date(
              data?.sanctionedLoanStartDate
            ).toISOString(),
            sanctionedLoanEndDate: new Date(
              data?.sanctionedLoanEndDate
            ).toISOString(),
          };
          if (path === "generateagreement") {
            props.apis
              .apiUnderWriterGenerateAgreement(payload, {
                loanAccountNumber: data.loanAccountNumber,
              })
              .then((res) => {
                resolve(res);
              });
          } else {
            props.apis
              .apiUnderWriterReGenerateAgreement(payload, {
                loanAccountNumber: data.loanAccountNumber,
              })
              .then((res) => {
                resolve(res);
              });
          }
        }
      });}else{
        swal2.fire({
          title: "Please select loan start date",
          // text: "Please try again later",
          icon: "warning",
          iconColor: "#FF4D4D",
        });
      }
  };

  const getData = () => {
    props.apis
      .apiGetUnderWriterLoanApplicationDetails({
        applicationNumber: props.urlparams.params.id,
      })
      .then((res) => {
        setData(res);
        let updatedFieldArray = updateDefaults(fields, res);
        setFields(updatedFieldArray);
        console.log(updatedFieldArray);
      })
      .catch((err) => {});
  };

  return (
    <>
      <MainPagetitle
        mainTitle={`My Applications | ${activeStatus.toTitleCase()} | ${
          path === "generateagreement" ? "Generate" : "Re-generate"
        } Agreement`}
        back={data?.borrowerName || ""}
        backLink={`/app/loanapplications?activetab=${activeStatus}`}
        pageTitle={"My Application"}
        parentTitle={"Home"}
        pageIcon={imagepaths.applicationCheckImg}
      />
      <div className="container-fluid">
        <div className="card p-4">
          <AppForm className="mx-0" onSubmit={handleAgreement}>
            {fields?.map((field, i) => (
              <div className={`col-12 col-md-${field?.colspan || 4}`}>
                <AppInput {...field}></AppInput>
              </div>
            ))}

            <div className="nav_container">
              <Link
                to={`/app/loanapplications?activetab=${activeStatus}`}
                className="btn btn-primary-lite me-3"
              >
                Cancel
              </Link>
              {/* <button
                type="button"
                className="btn btn-primary-lite me-3"
                onClick={(...args) => handleShow(...args)}
              >
                Preview
              </button> */}
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </AppForm>
        </div>
      </div>

      {/* PREVIEW MODAL */}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="xl"
        className="preview_agreement_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Preview Agreement</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <p>[Loan Agreement]</p>
          <p>
            This Loan Agreement ("Agreement") is entered into on [Date], between
            [Lender Name], located at [Address], hereinafter referred to as the
            "Lender", and [Borrower Name], located at [Address], hereinafter
            referred to as the "Borrower".
          </p>

          <p className="title">1. Loan Details:</p>
          <ul className="dotted">
            <li>Borrower Name: [Borrower Name]</li>
            <li>Loan Amount: [Loan Amount]</li>
            <li>Loan Tenure: [Loan Tenure]</li>
            <li>Rate of Interest (ROI): [Rate of Interest]</li>
            <li>Processing Fee (PF): [Processing Fee]</li>
            <li>Loan Start Date: [Loan Start Date]</li>
            <li>Loan End Date: [Loan End Date]</li>
          </ul>

          <p className="title">2. Terms and Conditions:</p>
          <ul className="letters">
            <li>
              The Loan Amount of [Loan Amount] shall be provided to the Borrower
              by the Lender.
            </li>
            <li>
              The Loan Tenure shall be [Loan Tenure] months, commencing from the
              Loan Start Date and ending on the Loan End Date.
            </li>
            <li>
              The Rate of Interest (ROI) applicable to this loan shall be [Rate
              of Interest] per annum.
            </li>
            <li>
              A Processing Fee (PF) of [Processing Fee] shall be charged by the
              Lender.
            </li>
            <li>
              The Borrower shall repay the Loan Amount along with accrued
              interest and processing fee in equal monthly installments within
              the Loan Tenure.
            </li>
            <li>
              The Borrower shall adhere to the repayment schedule as agreed upon
              in this Agreement.
            </li>
            <li>
              The Loan shall be secured by [Specify any collateral or security,
              if applicable].
            </li>
          </ul>

          <p className="title">3. Rights and Obligations:</p>
          <ul className="letters">
            <li>
              The Lender reserves the right to demand immediate repayment of the
              outstanding loan amount in case of default by the Borrower.
            </li>
            <li>
              The Borrower shall notify the Lender in writing of any change in
              contact information or financial status.
            </li>
            <li>
              The Borrower shall not assign or transfer this Agreement without
              the prior written consent of the Lender.
            </li>
          </ul>

          <p className="title">4. General Provisions:</p>
          <ul className="letters">
            <li>
              This Agreement constitutes the entire understanding between the
              parties concerning the subject matter hereof and supersedes all
              prior agreements and understandings, whether oral or written.
            </li>
            <li>
              Any modification to this Agreement must be made in writing and
              signed by both parties.
            </li>
            <li>
              This Agreement shall be governed by and construed in accordance
              with the laws of [State/Country].
            </li>
          </ul>

          <br />

          <p>
            IN WITNESS WHEREOF, the parties hereto have executed this Agreement
            as of the date first above written.
          </p>
          <p>[Lender Name] (Lender) </p>
          <p>[Borrower Name] (Borrower)</p>
          <p>[Signature] [Signature]</p>
          <p>[Printed Name] [Printed Name]</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default constore(GenerateAgreement);
