import React, { useEffect, useRef, useState } from "react";

const LeadInfo = (props) => {
  const infoForm = useRef(null);
  const [leadsInfoData, setLeadsInfoData] = useState({});
  const [timeline, setTimeline] = useState("TODAY");

  const leadsInfoCardData = [
    {
      id: "totalleads",
      title: "Total Leads",
      count: leadsInfoData?.totalLeads || 0,
      icon: imagepaths.AdminLeadsIconActive,
      path: `/app/portfolioinfo?cardname=totalleads&tablename=leads`,
    },
    {
      id: "digitalleads",
      title: "Digital Leads",
      count: leadsInfoData?.socialLeads || 0,
      icon: imagepaths.userWarningIcon,
      path: `/app/portfolioinfo?cardname=digital&tablename=leads`,
    },
    {
      id: "offlineleads",
      title: "Offline Leads",
      count: leadsInfoData?.offlineLeads || 0,
      icon: imagepaths.userCheckmarkIcon,
      path: `/app/portfolioinfo?cardname=offline&tablename=leads`,
    },
    {
      id: "enquiresleads",
      title: "Enquires Leads",
      count: leadsInfoData?.enquiryLeads || 0,
      icon: imagepaths.userRightIcon,
      path: `/app/portfolioinfo?cardname=enquiry&tablename=leads`,
    },
  ];

  // ADMIN LEADS INFO
  const getAdminLeadsInfo = (query, params) => {
    let data = {
      ...(params && params === "CUSTOM"
        ? {
            ...query,
            timeline: "CUSTOM",
          }
        : {
            ...query,
            timeline: timeline,
          }),
    };

    console.log({ params, data, timeline });

    props.apis.apiGetDashboardAdminLeadsInfo(data).then((res) => {
      setLeadsInfoData(res);
    });
  };

  const showInputs = (inputnames) => {
    if (inputnames.length > 0) {
      inputnames.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          element.classList.remove("d-none");
        }
      });
    }
  };

  const hideInputs = (inputnames) => {
    if (inputnames.length > 0) {
      inputnames.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          element.classList.add("d-none");
        }
      });
    }
  };

  const handleDateChange = (value) => {
    let val = value[0]?.key || "TODAY";
    let inputs = [
      "lead-column-startDate",
      "lead-column-endDate",
      "lead-column-search_btn",
      "lead-column-reset_btn",
    ];
    console.log({ val });
    if (val === "CUSTOM") {
      showInputs(inputs);
      setLeadsInfoData({});
    } else {
      setTimeline(val);
      hideInputs(inputs);
    }
  };

  const onSearch = () => {
    let data = infoForm.current.form.current.getData();
    let queryData = {
      ...Object.except(data, ["timeline"]),
    };
    getAdminLeadsInfo(queryData, "CUSTOM");
  };

  const resetSearchForm = () => {
    infoForm?.current?.form?.current?.reset();
    let inputs = [
      "lead-column-startDate",
      "lead-column-endDate",
      "lead-column-search_btn",
      "lead-column-reset_btn",
    ];
    hideInputs(inputs);
    getAdminLeadsInfo({}, "TODAY");
  };

  useEffect(() => {
    // ADMIN LEADS INFO
    getAdminLeadsInfo();
  }, [timeline]);

  props.$setNode({
    props,
    leadsInfoData,
  });
  return (
    <>
      <div className="mb-4 dashboard_form_box">
        <div className="form_outer">
          <AppForm
            ref={infoForm}
            onSubmit={(...args) => onSearch(...args)}
            className="row justify-content-end"
          >
            <div
              className="col-12 col-md-3 col-lg-3 col-xl-2"
              id="column-timeline"
            >
              <AppInputFocus
                type="select"
                name="timeline"
                label="Time Line"
                options={_enum.OVERALL_DETAIL}
                onChange={(...args) => handleDateChange(...args)}
                defaultValue={"TODAY"}
                placeholder="Time Line"
              ></AppInputFocus>
            </div>
            <div
              className="col-12 col-md-3 col-lg-3 col-xl-2 d-none"
              id="lead-column-startDate"
            >
              <AppInputFocus
                type="date"
                name="startDate"
                label="Start Date"
                placeholder="Start Date"
                required={true}
              ></AppInputFocus>
            </div>
            <div
              className="col-12 col-md-3 col-lg-3 col-xl-2 d-none"
              id="lead-column-endDate"
            >
              <AppInputFocus
                type="date"
                name="endDate"
                label="End Date"
                placeholder="End Date"
                required={true}
              ></AppInputFocus>
            </div>
            <div
              className="col-2 col-md-1 col-lg-1 col-xl-1 d-none"
              id="lead-column-search_btn"
            >
              <button type="submit" className="btn btn-primary search_btn">
                <i className="fa fa-search"></i>
                <span>Search</span>
              </button>
            </div>
            <div
              className="col-2 col-md-1 col-lg-1 col-xl-1 d-none"
              id="lead-column-reset_btn"
            >
              <button
                type="button"
                className="btn btn-primary search_btn"
                onClick={(...args) => resetSearchForm(...args)}
              >
                <i class="fa-solid fa-filter-circle-xmark"></i>
                <span>Search</span>
              </button>
            </div>
          </AppForm>
        </div>
        <div className="row pt-0 pt-md-4">
          {leadsInfoCardData &&
            leadsInfoCardData.map((data) => {
              return (
                <>
                  <div
                    className="col-xl-3 col-sm-6"
                    onClick={(...args) => navigate(data?.path)}
                  >
                    <div className="card dashboard_card">
                      <div className="border_bottom_line"></div>
                      <div className="card-body">
                        <div className="icon">
                          <img src={data?.icon} alt={data?.title} />
                        </div>
                        <div className="content">
                          <p className="count">{data?.count}</p>
                          <p className="card_label">{data?.title}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default constore(LeadInfo);
