import moment from "moment";
import React, { useState } from "react";
import OTPInput from "react-otp-input";

const AadharVerification = (props) => {
  const [aadharcode, setAadharcode] = useState();
  const [isAadharDisabled, setIsAadharDisabled] = useState(true);
  const [isAadharOtpDisabled, setIsAadharOtpDisabled] = useState(true);
  // const { name, address } = props?.aadhardetails;
  const isAadharVerified =
    props?.editdata?.customerAadharCardInfo === null ? false : true;

  const isAadharBypassEnabled =
    props?.editdata?.isAadharBypassEnabled === null ? false : true;

  const [showAadharVerify, setShowAadharVerify] = useState(false);
  const [showAadharDetails, setShowAadharDetails] = useState(false);
  const [aadharRequestDetails, setAadharRequestDetails] = useState({});
  const [aadhardetails, setAadhardetails] = useState({});

  const [showManualVerificationUi, setShowManualVerificationUi] =
    useState(false);
  const [aadharManualVerificationFields, setAadharManualVerificationFields] =
    useState([
      {
        name: "aadhaar_number",
        label: "Aadhar No.",
        type: "text",
        placeholder: "Aadhar No.",
        // onChange: (...args) => onChangeAadhar(...args),
        required: true,
      },
      {
        name: "full_name",
        label: "Full Name (As per Aadhar)",
        type: "text",
        placeholder: "Customer Name",
        required: true,
      },
      {
        name: "dob",
        label: "DOB",
        type: "date",
        disabledDate: (current) => {
          const today = moment();
          const maxDate = today.subtract(18, "years");
          return current && current > maxDate.endOf("day");
        },
        defaultValue: moment().subtract(18, "years"),
        placeholder: "Please enter",
        required: true,
      },
      {
        name: "gender",
        label: "Gender",
        type: "select",
        options: _enum.GENDER_SHORT,
        placeholder: "Gender",
        required: true,
      },
      {
        name: "street",
        label: "Street",
        type: "text",
        placeholder: "Street",
        required: true,
      },
      {
        name: "dist",
        label: "District",
        type: "text",
        placeholder: "District",
        required: true,
      },
      {
        name: "state",
        label: "State",
        type: "text",
        placeholder: "State",
        required: true,
      },
      {
        name: "zip",
        label: "Pincode",
        type: "number",
        placeholder: "Pincode",
        required: true,
      },
      {
        name: "house",
        label: "Address 1",
        type: "textarea",
        placeholder: "Address 1",
        customcolspan: 12,
        required: true,
      },
    ]);

  const onChangeAadhar = (e) => {
    let value = e.target.value;
    if (value.length > 0) {
      setIsAadharDisabled(false);
    } else {
      setIsAadharDisabled(true);
    }
  };

  function handleChange(otp) {
    console.log("otp changed: ", otp);
    setAadharcode(otp);
    if (otp.length === 6) {
      setIsAadharOtpDisabled(false);
    } else {
      setIsAadharOtpDisabled(true);
    }
  }

  const handleAadharVerify = (e, aadharcode) => {
    setAadharcode();
    onAadharVerify(e, aadharcode);
  };

  // AADHAR
  const onAadharCheck = (e, data) => {
    e.preventDefault();
    console.log({ onAadharCheck: data });
    setIsAadharDisabled(true);
    //
    let payload = {
      aadhaarNumber: data.aadharNumber,
    };
    console.log({ payload });

    const resolve = (res) => {
      props.store("user", {
        ...props?.$store?.user,
        customerAadharCardInfo: [
          {
            address: res?.address,
            name: res?.name,
            pincode: res?.pincode,
            uid: res?.uid,
          },
        ],
      });
    };
    props.apis.apiGetCustomerAadharDetails(payload).then(({ data }) => {
      console.log({ apiGetCustomerAadharDetails: data });
      toast.success("OTP Sent Successfully", toastConfig);
      resolve(data);
      setAadharRequestDetails(data);
      setShowAadharVerify(true);
    });
    // setAadharRequestDetails({});
    // setShowAadharVerify(true);
  };

  const showManualVerification = () => {
    setShowManualVerificationUi(true);
  };

  const onAadharManualVerificationSubmit = (e, data) => {
    let payload = {
      ...data,
    };
    console.log({ payload });
    props.apis.apiVerifyCustomerAadharDetailsManually(payload).then((res) => {
      setShowManualVerificationUi(false);
      props.handleSelect("pan");
    });
  };

  const onAadharVerify = (e, otpcode) => {
    e.preventDefault();
    setShowAadharVerify(false);
    //
    console.log({ onAadharVerify: otpcode });
    let payload = {
      requestId: aadharRequestDetails?.requestId,
      otp: otpcode,
    };
    console.log({ payload });
    props.apis.apiVerifyCustomerAadharDetails(payload).then((res) => {
      console.log({ apiVerifyCustomerAadharDetails: res });
      setAadhardetails(res);
      setShowAadharDetails(true);
    });
    // setAadhardetails({
    //   name: "Ravindra M",
    //   address: "House no. 49, Vivekananda colony, Badangpet, Hyderabad",
    // });
    // setShowAadharDetails(true);
  };

  // TOGGLE FUNCTION
  const handleAadharDetailsShow = () => {
    setShowAadharDetails(true);
  };

  const handleAadharDetailsClose = () => {
    setShowAadharDetails(false);
  };

  const onAadharSubmit = (e, aadharcode) => {
    e.preventDefault();
    toast.success("Aadhar Verified Successfully", toastConfig);
    setShowAadharVerify(false);
    setShowAadharDetails(false);
    props.handleSelect("pan");
    console.log(props);
  };

  const toastConfig = {
    autoClose: 3000,
    closeButton: false,
    hideProgressBar: true,
  };
  return (
    <>
      {!isAadharVerified && (
        <>
          {!showManualVerificationUi && (
            <AppForm onSubmit={onAadharCheck}>
              <div className="col-12 mb-3">
                <label htmlFor="mobileNumber">Aadhar No.</label>
                <input
                  type="number"
                  label="Mobile No."
                  className="form-control"
                  onChange={(e) => onChangeAadhar(e)}
                  name="aadharNumber"
                  required={true}
                />
              </div>
              <div className="col-12 d-flex align-items-center justify-content-end">
                <button
                  type="button"
                  className="btn btn-danger light me-2"
                  onClick={(...args) => showManualVerification(...args)}
                  disabled={!isAadharBypassEnabled}
                >
                  Verify Manually
                </button>
                <button
                  type="submit"
                  className="btn btn-primary px-5"
                  disabled={isAadharDisabled}
                >
                  Verify
                </button>
              </div>
            </AppForm>
          )}
        </>
      )}
      {/* AADHAR OTP VERIFICATION CONTAINER */}
      <div
        className={Object.className({
          "otp_box mt-5": true,
          hidden: !showAadharVerify,
        })}
      >
        <div className="col-12">
          <h4>OTP to verify</h4>
          <p className="m-0">We have sent an OTP to your mobile no.</p>
        </div>
        <div className="row">
          <div className="col-12 col-xl-6">
            <OTPInput
              value={aadharcode}
              onChange={(...args) => handleChange(...args)}
              numInputs={6}
              separator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
              containerStyle="otp_container"
            />
          </div>
          <div className="col-12 col-xl-6 d-flex align-items-center">
            <button
              className="btn btn-primary fs-5"
              disabled={isAadharOtpDisabled}
              onClick={(...args) => handleAadharVerify(...args, aadharcode)}
            >
              Verify
            </button>
          </div>
          <div className="col-12">
            <div className="text-start otp_bottom">
              <span className="me-2">Not received OTP?</span>
              <button className="btn px-0 text-primary fw-bold">Resend</button>
            </div>
          </div>
        </div>
      </div>
      {/* AADHAR DETAILS CONTAINER */}

      {isAadharVerified ? (
        // VIEW MODE CARD
        <div
          className={Object.className({
            profile_details_container: true,
          })}
        >
          <div className="profile_detail mb-3">
            <p className="m-0">Name as per Aadhar</p>
            <h4>{props?.editdata?.customerAadharCardInfo?.name || "-"}</h4>
          </div>
          <div className="profile_detail mb-3">
            <p className="m-0">Address as per Aadhar</p>
            <h4>{props?.editdata?.customerAadharCardInfo?.address || "-"}</h4>
          </div>
          <div className="nav_container">
            <button
              className="btn btn-primary"
              onClick={(...args) => props.handleSelect("pan")}
            >
              Next
            </button>
          </div>
        </div>
      ) : (
        <div
          className={Object.className({
            profile_details_container: true,
            hidden: !showAadharDetails || isAadharVerified,
          })}
        >
          <div className="profile_detail mb-3">
            <p className="m-0">Name as per Aadhar</p>
            <h4>{aadhardetails?.name || "-"}</h4>
          </div>
          <div className="profile_detail mb-3">
            <p className="m-0">Address as per Aadhar</p>
            <h4>{aadhardetails?.address || "-"}</h4>
          </div>
          <button
            className="btn btn-primary"
            onClick={(...args) => onAadharSubmit(...args, aadharcode)}
          >
            Save & Submit
          </button>
        </div>
      )}

      {showManualVerificationUi && (
        <>
          <AppForm onSubmit={onAadharManualVerificationSubmit}>
            {aadharManualVerificationFields?.map((field, i) => {
              return (
                <div
                  className={`col-12 col-md-${field?.customcolspan || 6}`}
                  key={i}
                >
                  <AppInput {...field}></AppInput>
                </div>
              );
            })}
            <div className="nav_container">
              <button
                type="button"
                className="btn btn-primary-lite me-3"
                onClick={(...args) => setShowManualVerificationUi(false)}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                {!true ? "Update & Preview" : "Save & Submit"}
              </button>
            </div>
          </AppForm>
        </>
      )}
    </>
  );
};

export default constore(AadharVerification);
