import React, { useEffect, useState } from "react";

import MainPagetitle from "../../../layouts/MainPagetitle";

const SalesInfo = (props) => {
  var cardname = props.urlparams.params.cardname;
  var timeline = props.urlparams.params.timeline;
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "borrowerName",
      title: "Customer Name",
      sorter: (a, b) => a.customerName.length - b.customerName.length,
      render: (text, record) => {
        return <h6 className="text-primary">{text}</h6>;
      },
    },
    {
      dataIndex: "loanAccountNumber",
      title: "LAN No.",
      render: (text, record) => {
        return <span>{text || "-"}</span>;
      },
    },
    {
      dataIndex: "sanctionedLoanAmount",
      title: "Sanctioned amt.",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "netDisbursementAmt",
      title: "Disbursement Amt.",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "sanctionedLoanPF",
      title: "Processing Fees",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "sanctionedLoanTotalAmtPayable",
      title: "Total Payable",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "totalRepaymentAmtTillDate",
      title: "Repay Amt",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "totalOutstandingAmtTillDate",
      title: "Outstanding Amt.",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "sanctionedLoanAmount",
      title: "Principle amt.",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "sanctionedLoanTenure",
      title: "Tenure",
      render: (text, record) => {
        return <span>{`${text} Days` || "-"}</span>;
      },
    },
    {
      dataIndex: "loanInterestAmtTillDate",
      title: "Loan Int. Amt.",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
  ];

  const [tableColumns, setTableColumns] = useState(columns);

  const onPageChange = (pageData) => {
    let payload = {
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 10,
    };
    console.log({ onPageChange: pageData, payload });
    getData(payload);
  };

  const downloadFile = async () => {
    let payload = {};

    let startDateValue = document.querySelector("[name='start_date']")?.value;
    let endDateValue = document.querySelector("[name='end_date']")?.value;

    if (startDateValue) {
      payload.startDate = startDateValue;
    }
    if (endDateValue) {
      payload.endDate = endDateValue;
    }

    // props.apis.apiExportDisbursementReport(payload).then(async (res) => {
    //   if (res) {
    //     let data = await res;
    //     let blob = new Blob([data], {
    //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //     });
    //     let link = document.createElement("a");
    //     link.href = URL.createObjectURL(blob);
    //     link.setAttribute(
    //       "download",
    //       `disbursement_report_${new Date().toDate()}.xlsx`
    //     );
    //     link.click();
    //   }
    // });
  };

  // REVENUE INFO LIST
  const getTotalDisbursementList = (query, params) => {
    let data = {
      ...search,
      ...query,
    };

    let payload = {};
    if (params) {
      payload = {
        timeline: params,
      };
    } else {
      payload = {
        timeline: timeline,
      };
    }
    console.log({ data, payload });

    props.apis
      .apiGetTotalDisbursementList(data, payload)
      .then(({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };

  const getDisbursementDetailsByChannelList = (query, params) => {
    let data = {
      ...search,
      ...query,
    };

    let payload = {};
    if (params) {
      payload = {
        timeline: params,
        channel: _enum.DISBURSEMENT_STATUS_MAP[cardname],
      };
    } else {
      payload = {
        timeline: timeline,
        channel: _enum.DISBURSEMENT_STATUS_MAP[cardname],
      };
    }
    console.log({ data, payload });

    props.apis
      .apiGetDisbursementDetailsByChannelList(data, payload)
      .then(({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };

  const getData = (searchPayload, timeline) => {
    setTableColumns(columns);

    if (cardname === "totalDisbursement") {
      getTotalDisbursementList(searchPayload, timeline);
    } else {
      getDisbursementDetailsByChannelList(searchPayload, timeline);
    }

    // scroll page to top
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getData(search, timeline);
  }, []);

  props.$setNode({
    props,
  });

  return (
    <>
      <MainPagetitle
        mainTitle="Dashboard"
        back={"Sales Info. Data"}
        backLink={`/app/home`}
        pageTitle={"Dashboard"}
        parentTitle={"Home"}
        pageIcon={imagepaths.DashboardActive}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="tbl-caption-container"></div>
                <div className="action_btn_group text-end mb-4">
                  <button
                    type="button"
                    className="btn btn-primary ms-1 me-5"
                    onClick={(...args) => downloadFile(...args)}
                  >
                    <i className="fa fa-download me-2"></i>
                    Download Excel
                  </button>
                </div>
                <AppTable
                  data={tableData}
                  columns={tableColumns}
                  onChange={(...arg) => onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  editable={false}
                  viewable={false}
                  deletable={false}
                  loading={isFetching}
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default constore(SalesInfo);
