import { useEffect, useRef, useState } from "react";
import MainPagetitle from "../../../layouts/MainPagetitle";
import { Button, Form, Modal } from "react-bootstrap";
import FlexCard from "../LoanApplications/Helpers/FlexCard";

const bsa = (props) => {
  const [bsaTableData, setBsaTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [bsaRowcount, setBsaRowcount] = useState(0);
  const bsaForm = useRef(null);
  const [passcheck, setPasscheck] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [summaryScoreData, setSummaryScoreData] = useState(null);
  const [bsaReportURL, setBsaReportURL] = useState(null);

  const bsaColumns = [
    {
      dataIndex: "id",
      title: "Statement Id",
    },
    // {
    //   dataIndex: "workOrderId",
    //   title: "Worker Order Id",
    // },
    {
      dataIndex: "directURL",
      title: "Statement Uploaded",
      render: (text, record) => {
        if (text) {
          return (
            <div>
              <a href={`${text}`} target="_blank" className="text-primary">
                Download
              </a>
            </div>
          );
        } else {
          return <>-</>;
        }
      },
    },
    {
      dataIndex: "password",
      title: "File Password",
      render: (text, record) => {
        return <>{text || "-"}</>;
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      render: (text, record) => {
        return <>{text || "-"}</>;
      },
    },
    {
      dataIndex: "check",
      title: "Action",
      render: (text, record) => {
        return (
          <div className="row">
            <button
              className="btn btn-primary search_btn m-1"
              disabled={record?.status === "UPLOADED"}
              onClick={() => refreshBsaStatus(record)}
            >
              <i className="fa fa-magnifying-glass-chart"></i>
              <span>Search</span>
            </button>
          </div>
        );
      },
    },
  ];

  // TABLE ROW SELECTION
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => {
      if (record?.status === "UPLOADED") {
        return {
          disabled: false,
        };
      } else {
        return {
          disabled: true,
        };
      }
    },
  };

  useEffect(() => {
    getBsaInfo();
  }, []);

  const getBsaReport = (record) => {
    props.apis
      .apiUnderWriterGetBSAReportSolo({ workOrderId: record.workOrderId })
      .then((res) => {
        if (res) {
          setBsaReportURL(res?.excel);
          window.scrollTo(0, document.body.scrollHeight);
        }
      });
  };

  const refreshBsaStatus = (record) => {
    props.apis
      .apiUnderWriterRefreshBSAStatus({ workOrderId: record.workOrderId })
      .then((res) => {
        if (res?.report?.reportStatus === "ANALYSED") {
          getBsaReport(record);
          getBsaInfo();
          let accountData = Object.only(res?.reportAccounts?.[0]?.account, [
            "accountName",
            "accountNumber",
            "bankName",
            "ifscCode",
            "accountTypeName",
            "startDate",
            "endDate",
          ]);
          setSummaryScoreData({
            ...accountData,
            bankScore: res.report.bankScore,
          });
        } else if (res?.report?.reportStatus === "ANALYSIS ON HOLD") {
          swal2.fire({
            title: "BSA Analysis On-Hold! \n Please check with team",
            icon: "success",
            iconColor: "#FF4D4D",
            timer: 2000,
          });
          getBsaInfo();
        } else if (res?.report?.reportStatus === "IN PROGRESS") {
          swal2.fire({
            title: "BSA generation In-progress! \n Please check in sometime",
            icon: "success",
            iconColor: "#FF4D4D",
            timer: 2000,
          });
        } else if (res?.report?.reportStatus === "pending") {
          generateReport();
        }
      });
  };
  const downloadReport = () => {};

  const getBsaInfo = () => {
    props.apis.apiUnderWriterGetBSAInfo().then((response) => {
      setBsaTableData(response.data);
      setIsFetching(false);
    });
  };

  const handlePasswordCheck = (e) => {
    let value = e.target.checked;
    setPasscheck(value);
  };

  const handleBsaSubmit = async (e, data) => {
    setLoading(true);

    props.apis.apiUnderWriterBSAUpload(data).then((res) => {
      if (res) {
        swal2.fire({
          title: "Bank Statement Uploaded Successfully.",
          icon: "success",
          iconColor: "#FF4D4D",
          timer: 4000,
        });
        setShow(false);
        setLoading(false);
        getBsaInfo();
      }
    });
  };

  const sendForAnalysis = () => {
    console.log({ selectedRowKeys });
    let payload = {
      statementIds: [...selectedRowKeys],
    };
    props.apis.apiUnderWriterAnalyzeBSAData(payload).then((res) => {
      if (res) {
        swal2.fire({
          title: "Bank Statement Sent for Processing.",
          icon: "success",
          iconColor: "#FF4D4D",
          timer: 4000,
        });
        setSelectedRowKeys([]);
        setLoading(false);
        getBsaInfo();
      }
    });
  };

  return (
    <>
      {/* Bank Statement Analyzer */}
      <MainPagetitle
        mainTitle="Bank Statement Analyzer"
        pageTitle="Bank Statement Analyzer"
        parentTitle={"Home"}
        pageIcon={imagepaths.applicationCheckActiveImg}
      />
      <div className="container-fluid">
        <div className="card p-4">
          <div className="form_group_card px-0">
            <div className="d-flex align-items-start justify-content-between">
              <h2 className="mb-3 theme_text_sm">Bank Statement Analyzer</h2>
              <div className="search_btn_group d-flex align-items-start">
                <button
                  type="button"
                  className={Object.className({
                    "btn btn-primary-transparent py-0": true,
                  })}
                  onClick={(...args) => setShow(true)}
                >
                  + Add Bank Statement
                </button>
              </div>
            </div>
            <Modal
              show={show}
              onHide={(...args) => setShow(false)}
              className="customer_application_modal payment_modal"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Bank Statement Analyzer</Modal.Title>
              </Modal.Header>
              <Modal.Body className="py-0"></Modal.Body>
              <AppForm
                ref={bsaForm}
                onSubmit={(...args) => handleBsaSubmit(...args)}
              >
                {/* <div className="col-12">
                <AppInput
                  name="bank_name"
                  label="Bank Name"
                  type="select"
                  options={_enum.BSA_BANKS}
                  required={true}
                ></AppInput>
              </div> */}
                <div className="col-12">
                  <AppInput
                    name="file"
                    label="Bank Statement"
                    type="defaultfile"
                    foldername="bsaDocs"
                    required={true}
                  ></AppInput>
                </div>

                <Form.Check
                  type="checkbox"
                  id={`passcheck`}
                  label="Password protected"
                  className="ms-3"
                  onChange={(...args) => handlePasswordCheck(...args)}
                />
                {passcheck ? (
                  <div className="col-12">
                    <AppInput
                      type="text"
                      label="Password"
                      name="password"
                    ></AppInput>
                  </div>
                ) : null}
                <div className="col-12 text-end">
                  <Button type="submit" variant="primary">
                    Upload
                  </Button>
                </div>
              </AppForm>
            </Modal>
            {/* <div className="row g-4 px-0 mt-3">
            {bankStatementFiles.length > 0 &&
              bankStatementFiles?.map((file, i) => {
                return (
                  <>
                    <div className="col-12 col-md-4 file_download_box">
                      <p className="label_text">Bank Statement - {i + 1}</p>
                      <a
                        href={`${BASE_URL}${file?.fileId}`}
                        className="file_download_btn"
                      >
                        <span>{file.filename}</span>
                        <i className="fa fa-download"></i>
                      </a>
                    </div>
                  </>
                );
              })}
          </div> */}

            <div>
              <AppTable
                data={bsaTableData}
                columns={bsaColumns}
                onChange={(...arg) => onPageChange(...arg)}
                total={bsaRowcount}
                rowSelection={rowSelection}
                reorder={true}
                editable={false}
                viewable={false}
                deletable={false}
                // actionBtn={true}
                // actionBtnAction={(...args) => fetchBSAStatus(...args)}
                // actionBtnText="Get Report"
                loading={isFetching}
              ></AppTable>
            </div>
            <div className="col-12 text-end">
              <button
                className={Object.className({
                  "btn btn-primary m-3": true,
                  disabled: selectedRowKeys.length == 0,
                })}
                onClick={(e) => sendForAnalysis()}
              >
                Send for Analysis
              </button>
            </div>

            <div
              className={Object.className({
                "card-body p-0": true,
                "d-none": !bsaReportURL,
              })}
            >
              <div className="action_btn_group text-end">
                <button type="button" className="btn btn-primary-transparent">
                  <a
                    href={`${bsaReportURL}`}
                    className="file_download_btn text-primary"
                  >
                    <i className="fa fa-download me-2"></i>
                    Download Excel
                  </a>
                </button>
              </div>
              {summaryScoreData && (
                <div className="col-12">
                  <FlexCard
                    className="grey_bordered darker"
                    title="Summary – Scorecard"
                    data={summaryScoreData}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default constore(bsa);
